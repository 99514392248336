@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #01647e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  overflow: hidden;
}

h1 {
  font-size: 52px;
  letter-spacing: 1px;
  line-height: 1.23;
  font-weight: 500;
}

h2 {
  font-size: 42px;
  line-height: 1.23;
  font-weight: 600;
  text-align: center;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 20px;
  font-weight: 600;
}

.burgerAnimation :first-child {
  transform: rotate(45deg) translateY(3px) translateX(2px);
  transition: all 0.4s ease;
}

.burgerAnimation :last-child {
  transform: rotate(-45deg) translateY(-4px) translateX(2px);
  transition: all 0.4s ease;
}

.nav__animation {

  animation: navAnimation 5s inline forwards;
}

@keyframes navAnimation {
  0% {
    top: -200vh;
  }

  100% {
    top: 0px;
  }
}


.container__standart {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 20px;
}

.container__big {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
  padding: 40px 20px;
}

.description {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  transform: translateY(30%);
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out, max-height 500ms ease-in-out, transform 300ms ease-in-out;
  transition-delay: 0s, 0s, 0s, 0.3s;
}

.description.visible {
  opacity: 1;
  visibility: visible;
  max-height: 500px;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0s, 0s;
}

.top__button {
  height: 50px;
  border-radius: 40px;
  background-color: #0d2951;
  color: white;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0px 20px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  z-index: 5;
}

.top__button::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  height: 70px;
  width: 20px;
  background: linear-gradient(to right, #ffffff4a 55%, #e5e5e5ad 100%);
  transform: rotate(30deg);
  z-index: 4;
  animation: buttonAnimation 3s linear infinite;
}

.top__button::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  height: 70px;
  width: 20px;
  background: linear-gradient(to right, #ffffff4a 55%, #e5e5e5ad 100%);
  transform: rotate(30deg);
  z-index: 4;
  animation: buttonAnimationFaster 3s linear infinite;
}

@keyframes buttonAnimation {
  0% {
    left: -40px;
  }

  100% {
    left: 350px;
  }
}

@keyframes buttonAnimationFaster {
  0% {
    left: -140px;
  }

  100% {
    left: 520px;
  }
}

/* form */
.form-label {
  font-weight: 300;
  font-size: 16px;
  float: left;
  color: #ffffff;
}

.form-req-info {
  font-weight: 300;
  font-size: 16px;
  position: absolute;
  bottom: -35%;
  left: 0;
  color: #eb5757;
}

.form-input {
  width: 100%;
  padding: 5px 0.85rem;
  font-size: 14px;
  height: 50px;
  background-color: #f5f8fa;
  border: 1px solid #cbd6e2;
  transition: border-color 0.3s;
  border-radius: 0.3rem;
  outline: none;
}

.form-input:focus {
  border: 1px solid #000000;
}

/* form */

@media(max-width:1024px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 38px;
  }
}
@media(max-width:929px) {

  h2 {
    font-size: 38px;
  }
}

@media(max-width:459px) {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 28px;
  }
}